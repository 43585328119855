<template>
    <div>
        <card-header title="New Registration" icon="fa-plus"/>

        <card-body>
            <card-list>
                <subheader-list-item icon="fa-align-left" title="Informatiom"/>
                <container-list-item padded>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Trip ID" label-position="inside">
                                <b-input v-model="newReg.trip_id"/>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Primary ID" label-position="inside">
                                <b-input v-model="newReg.user_id"/>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Second ID" label-position="inside">
                                <b-input v-model="newReg.second_id"/>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Primary Type" label-position="inside">
                                <b-select v-model="newReg.type" expanded>
                                    <option value="participant">Participant</option>
                                    <option value="coach">Coach</option>
                                    <option value="staff">Staff</option>
                                    <option value="waitlist">Wait List</option>
                                    <option value="cancelled">Cancelled</option>
                                </b-select>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Second Type" label-position="inside">
                                <b-select v-model="newReg.second_type" expanded>
                                    <option value="participant">Participant</option>
                                    <option value="coach">Coach</option>
                                    <option value="staff">Staff</option>
                                    <option value="waitlist">Wait List</option>
                                    <option value="cancelled">Cancelled</option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>
                </container-list-item>
            </card-list>
        </card-body>

        <card-footer class="pt-3 pb-3 has-text-right">
            <b-button type="is-primary" size="is-small" @click="submit" :disabled="!newReg.trip_id || !newReg.user_id || !newReg.type"><i class="fas fa-plus mr-3"></i>New Registration</b-button>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from '../../TIER/components/CardHeader';
    import CardBody from '../../TIER/components/CardBody';
    import CardFooter from '../../TIER/components/CardFooter';

    import {client as http} from '../../http_client';
    import {mapActions} from "vuex";
    import AddonForm from "./AddonForm";
    import CardList from "@/TIER/components/CardList";
    import ContainerListItem from "@/TIER/components/ContainerListItem";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";

    export default {
        props: ['card', 'props'],
        components: {SubheaderListItem, ContainerListItem, CardList, AddonForm, CardFooter, CardBody, CardHeader},
        data: function() {
            return {
                newReg: {},
                addon: {},
                tripAddons: [],
            };
        },
        methods: {
            submit() {
                this.$emit('loading', true);

                http.post('/api/registrations/', this.newReg, {force: true}).then(response => {
                    this.$reloadCard('registrations');
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            }
        },
        mounted() {
            this.$saveAlert(true, 'Are you done creating this new registration? Unsaved changes will be lost.');
        }
    };
</script>
